@font-face {
  font-family: "fc_iconic";
  src: url("fonts/fciconic-regular-webfont.woff2") format("woff2"),
    url("fonts/fciconic-regular-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "fc_iconic";
  src: url("fonts/fciconic-semibold-webfont.woff2") format("woff2"),
    url("fonts/fciconic-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "fc_iconic";
  src: url("fonts/fciconic-extrabold-webfont.woff2") format("woff2"),
    url("fonts/fciconic-extrabold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "fc_iconic";
  src: url("fonts/fciconic-thin-webfont.woff2") format("woff2"),
    url("fonts/fciconic-thin-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
