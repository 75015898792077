@tailwind base;
@tailwind components;
@tailwind utilities;

// Layout

body {
  text-align: center;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

input[type="tel"] {
  -moz-appearance: textfield;
}

#root,
.app,
body,
html {
  height: 100%;
}

.app {
  position: relative;
  display: flex;
  min-height: 100%;
}

body {
  transition: background-color 0.3s;
  &.bg-light {
    @apply bg-main-bg;
    .mode-light {
      opacity: 1;
    }
    .mode-dark {
      opacity: 0;
    }
  }

  &.bg-dark {
    @apply bg-support-normal;
    .mode-light {
      opacity: 0;
    }
    .mode-dark {
      opacity: 1;
    }
  }
}

.view-transition-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  -webkit-overflow-scrolling: touch;

  .view-transition-absolute {
    position: absolute;
    width: 100%;
    height: 100%;
    @apply md:overflow-y-auto;

    .view,
    .inside-middle {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      justify-content: center;
      min-height: 100%;

      .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

// Fix for HTML2Canvas

body > div:last-child > span + img {
  display: inline !important;
}

// Fonts

.iconic {
  font-family: "fc_iconic", sans-serif;
}

// Components

.view-middle {
  @apply transition;

  &.view-middle-light {
    @apply md:p-6 rounded-2xl md:bg-main-bg md:bg-opacity-80 md:border-2 md:border-main-light;
  }

  &.view-middle-dark {
    animation: goTop 0.3s 0.2s ease-in-out forwards;
    @apply md:p-6 opacity-0 rounded-t-2xl bg-white md:pb-0;
  }

  // &.view-middle-result {
  //   // @apply md:bg-main-bg md:bg-opacity-80;
  // }
}

.view-bottom {
  animation: goTop 0.3s 0.2s ease-in-out forwards;
  @apply opacity-0;
}

.btn {
  &.btn-primary {
    @apply flex flex-col w-full items-center rounded-full py-3 px-3 text-xl md:text-2xl font-semibold bg-support-normal text-white hover:bg-opacity-90 transition;

    &.disabled {
      opacity: 0.5;

      &:hover {
        @apply bg-opacity-100;
      }
    }
  }

  &.btn-secondary {
    @apply flex flex-col whitespace-nowrap items-center rounded-xl py-2 px-4 text-lg md:text-xl border-2 border-main-light  hover:border-main-normal bg-white text-support-deep hover:bg-main-bg transition;
  }

  &.btn-fill {
    @apply flex flex-row relative w-full items-center justify-center rounded-xl py-2 px-3 text-base md:text-lg border border-natural-light text-support-deep transition;

    &.btn-blue {
      @apply bg-support-normal;

      span {
        @apply text-white;
      }
    }

    &.active {
      span {
        @apply text-white;
      }
    }
  }

  &.btn-modal {
    @apply flex flex-col w-full items-center rounded-full py-2 px-3 text-lg md:text-xl font-semibold bg-support-normal text-white hover:bg-opacity-90 transition;

    &.btn-white {
      @apply bg-white hover:bg-gray-100 border text-support-normal;
    }

    &.disabled {
      opacity: 0.5;

      &:hover {
        @apply bg-opacity-100;
      }
    }
  }

  &.btn-text-icon {
    @apply flex flex-row items-center py-1;
  }
}

.candle {
  @apply flex flex-col justify-center items-center relative;

  .base {
    @apply bg-main-dark w-32 h-3 rounded-full;
  }

  .stick-full {
    @apply flex items-end justify-center border-dashed border-2 border-support-light rounded-t-lg;

    .stick-progress {
      @apply absolute -mb-0.5 rounded-t-xl bg-main-normal;
      box-shadow: inset 10px 0 0 0 rgba(255, 255, 255, 0.25);

      .top {
        @apply flex items-start justify-center relative h-0;

        .fire {
          @apply absolute w-full justify-center items-center;
          .fire-1 {
            animation: fire1 1s linear infinite;
            @apply absolute z-20 w-9 h-9 bg-main-normal;
            top: -42px;
            border-radius: 100% 0% 55% 45% / 55% 0% 100% 45%;
          }
          .fire-2 {
            animation: fire2 1s 0.1s linear infinite;
            @apply absolute z-20 w-7 h-7 bg-main-light;
            top: -34px;
            transform: rotateZ(-45deg);
            border-radius: 100% 0% 55% 45% / 55% 0% 100% 45%;
          }
          .fire-3 {
            animation: fire2 1.2s 0.3s linear infinite;
            @apply absolute z-20 w-4 h-4 bg-white;
            top: -24px;
            transform: rotateZ(-45deg);
            border-radius: 100% 0% 55% 45% / 55% 0% 100% 45%;
          }
          .fire-light {
            @apply absolute w-20 h-20 rounded-full bg-main-light bg-opacity-10;
            top: -64px;
          }
        }

        .wick {
          @apply absolute -top-2 w-1 h-2 bg-white;
        }

        .waxs {
          @apply rounded-t-xl absolute w-16 h-3 bg-main-light;

          .light {
            @apply absolute z-10 top-1 left-2 h-1 bg-white w-12 rounded-full;
          }

          .wax-1 {
            @apply absolute left-0 rounded-xl w-4 h-7 bg-main-light;
          }

          .wax-2 {
            @apply absolute right-0 rounded-t-xl rounded-b-md w-5 h-5 bg-main-light;
          }

          .wax-3 {
            @apply absolute left-2 rounded-t-xl rounded-b-md w-5 h-4 bg-main-light;
          }
        }

        .shadow-candle {
          @apply w-12 h-5 bg-natural-dark bg-opacity-20;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-main-light;
  }
}

@media screen and (max-height: 540px) {
  body {
    .box-scroll.overflow-auto {
      max-height: inherit !important;
    }
  }
}
