.fade-enter {
  opacity: 0;
  transform: translateY(-10%) scale(0.9);
  z-index: 9;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateY(0) scale(1);
  transition: all 500ms ease-in-out;
  z-index: 9;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0) scale(1);
  z-index: 5;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translateY(10%) scale(0.9);
  transition: all 500ms ease-in-out;
  z-index: 5;
}

@keyframes goTop {
  0% {
    bottom: -150px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes fire1 {
  0%,
  100% {
    transform: rotateZ(-45deg) skew(0deg);
  }
  25% {
    transform: rotateZ(-40deg) skew(6deg) translateY(1px) scale(1.05);
  }
  75% {
    transform: rotateZ(-45deg) skew(-6deg) translateY(-1px) scale(0.9);
  }
}

@keyframes fire2 {
  0%,
  100% {
    transform: rotateZ(-45deg) skew(0deg);
  }
  25% {
    transform: rotateZ(-40deg) skew(8deg) translateY(1px) scale(1.05);
  }
  75% {
    transform: rotateZ(-45deg) skew(-10deg) translateY(-1px) scale(0.9);
  }
}
